/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */
import _ from 'lodash';
import React from 'react';
import { decorate, computed, action, runInAction, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Table } from 'semantic-ui-react';

import { displayError } from '@amzn/base-ui/dist/helpers/notification';

const openWindow = (url, windowFeatures) => {
  return window.open(url, '_blank', windowFeatures);
};

class ScEnvironmentDcvConnectionRow extends React.Component {
  constructor(props) {
    super(props);
    runInAction(() => {
      this.generatingToken = false;
    });
  }

  get environment() {
    return this.props.scEnvironment;
  }

  get envsStore() {
    return this.props.scEnvironmentsStore;
  }

  getConnectionStore() {
    return this.envsStore.getScEnvConnectionStore(this.environment.id);
  }

  // Returns only the connections that scheme = 'dcv'
  // [ {id, name: <string>(optional), instanceId: <string>, scheme: 'dcv'}, ... ]
  get connections() {
    const connections = this.environment.getConnections(item => item.scheme === 'dcv');

    return connections;
  }

  get connection() {
    const id = this.connectionId;
    const connections = this.connections;

    return _.find(connections, ['id', id]) || {};
  }

  get connectionId() {
    return this.props.connectionId;
  }

  handleConnectBrowser = id => this.handleConnect(id, false);

  handleConnectClient = id => this.handleConnect(id, true);

  handleConnect = (id, useClientProtocol) =>
    action(async () => {
      const store = this.getConnectionStore();
      const connections = this.environment.connections;
      const connectInfo = _.find(connections, ['id', id]) || {};
      let url = connectInfo.url;

      runInAction(() => {
        this.processingId = id;
        this.generatingToken = true;
      });

      try {
        if (url) {
          if (useClientProtocol) {
            url = url.replace('https://', 'dcv://');
          }
          // We use noopener and noreferrer for good practices https://developer.mozilla.org/en-US/docs/Web/API/Window/open#noopener
          openWindow(url, 'noopener,noreferrer');
        } else {
          const urlObj = await store.createConnectionUrl(id);
          url = urlObj.url;
          if (url) {
            if (useClientProtocol) {
              url = url.replace('https://', 'dcv://');
            }
            if (connectInfo.type.toLowerCase() === 'dcv') {
              openWindow(url, 'noopener,noreferrer');
            } else {
              const newTab = openWindow('about:blank');
              newTab.location = url;
            }
          }
        }
      } catch (error) {
        displayError(error);
      } finally {
        runInAction(() => {
          this.processingId = '';
          this.generatingToken = false;
        });
      }
    });

  render() {
    const processingId = this.processingId;
    const connections = this.connections;
    const isDisabled = id => processingId !== id && !_.isEmpty(processingId);
    return (
      <>
        {_.map(connections, item => (
          <>
            <Table.Row key={item.id}>
              <Table.Cell className="clearfix">
                <Button
                  floated="right"
                  size="mini"
                  primary
                  disabled={isDisabled(item.id)}
                  loading={this.generatingToken}
                  onClick={this.handleConnectClient(item.id)}
                  data-testid="connect-using-dcv-button"
                >
                  Open DCV (Client)
                </Button>
                <Button
                  floated="right"
                  size="mini"
                  primary
                  disabled={isDisabled(item.id)}
                  loading={this.generatingToken}
                  onClick={this.handleConnectBrowser(item.id)}
                  data-testid="connect-using-dcv-button"
                >
                  Open DCV (Browser)
                </Button>
                <div className="mt1">{item.name || 'Connect'}</div>
              </Table.Cell>
            </Table.Row>
          </>
        ))}
      </>
    );
  }
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(ScEnvironmentDcvConnectionRow, {
  envsStore: computed,
  environment: computed,
  connections: computed,
  connection: computed,
  connectionId: computed,
  handleConnect: action,
  generatingToken: observable,
});

export default inject('scEnvironmentsStore')(withRouter(observer(ScEnvironmentDcvConnectionRow)));
