import _ from 'lodash';

const Component = ({ publicHostname, username, password = null }) => {
  if (_.isEmpty(publicHostname) || _.isEmpty(username)) {
    return null;
  }

  // To-Do: Encrypt the password into byte stream format compatible with RDP. 
  // This will need to be done through SSM as Windows is required to encrypt the password specifically for RDP.
  const encryptedPassword = password;

  const rdpFileContents = [
    `full address:s:${publicHostname}`,
    `username:s:${username}`,
    encryptedPassword ? `password 51:b:${encryptedPassword}` : null,
    encryptedPassword ? 'prompt for credentials:i:0' : 'prompt for credentials:i:1',
    'disable full window drag:i:1',
    'redirectclipboard:i:1',
    'redirectprinters:i:1',
    'drivestoredirect:s:C:\;',
    'autoreconnection enabled:i:1'
  ].filter(Boolean).join('\r\n') + '\r\n';

  return rdpFileContents;
};

export default Component;